<template>
  <div>
    <container :loading="loading" :body-props="{ class: 'relative' }">
      <template v-slot:heading>{{ pageHeading }}</template>

      <ul class="tabs">
        <router-link v-for="tab in tabs" :to="tab.path" class="tab-item" exact-active-class="tab-active">{{tab.tabTitle}}</router-link>
      </ul>

      <div class="border border-gray-300 p-4 py-6 relative">
          <router-view @loaded="loading = false" v-fade-in="!loading"></router-view>
      </div>

    </container>
  </div>
</template>

<script>
import Container from "../../layouts/container";
import Btn from "../../../components/btn";
import profileItem from "./components/profile-item";
import profileTabs from  "./routes";
export default {
  name: "index",
  data: {
    loading: true,
    profile_data: { }
  },
  components: {Btn, Container},
  computed: {
    pageHeading(){
      return this.$route.meta.heading;
    },
    tabs(){
      return profileTabs.map( item => ({
        path: `/my/profile/${item.path}`,
        tabTitle:  typeof item.meta == 'object' ? item.meta.tabTitle:'',
      }))
    }
  },
  methods: { },
  created() { },
  watch:{
    '$route.fullPath'(){
      this.loading = true
    }
  }
};
</script>

<style scoped>

  .tabs {
    @apply w-full flex
  }

  .tabs .tab-item {
    @apply px-3 py-1 bg-gray-200 rounded-t
  }

  .tabs .tab-item:hover {
    @apply bg-gray-300
  }

  .tabs .tab-active, .tabs .tab-active:hover {
    @apply bg-blue-400 text-white
  }
</style>
